// Start with assigning color names to specific hex values.
$white:                     #ffffff  !default;
$black:                     #000000  !default;
$red:                       #ff4400  !default;
$orange:                    #fa8c16  !default;
$gold:                      #ffc542  !default;
$green:                     #21B573  !default;
$blue:                      #00b4d8  !default; 
$cyan:                      #04d182  !default;
$purple:                    #00054a  !default; 
$magenta:                   #eb2f96  !default;
$volcano:                   #ff6b72  !default;
$lime:                      #a0d911  !default;
$geekblue:                  #17bcff  !default;

// Create grayscale
$gray-dark:                 #00054a  !default;
$gray:                      #455560  !default;
$gray-light:                #72849a  !default; 
$gray-lighter:              #ededed  !default;
$gray-lightest:             #f7f7f8  !default;

// Gruu colors
$gruu-primary:               #e50000  !default;
$brand-primary:              #e50000   !default;
$font-primary:               #e50000   !default;
$font-secondary:             #194876  !default;
$brand-secondary:            #00D999  !default;
$brand-accent:               #7a7a7a  !default;

$sidebar-text-active:		$font-primary !default;
$sidebar-bg-hover:			brand-primary !default;
$sidebar-bg-active:			brand-primary !default;